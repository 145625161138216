import store from '@/store/index'
import Cookies from 'js-cookie';

async function isAuthenticated(to, from, next) {

  if (store.getters.isAuthenticated && store.getters.verifyEmail === false && to.name !== 'VerificaEmail') {
    if (to.name === 'VerificaEmailHash') {
      return next()+window.location.search
    } else {
      if (to.path != from.path)
        return next(`/verificar-email${window.location.search}`)
    }
  }
  
  let userPermissions = []
  if (store.getters.isAuthenticated && store.getters.getAllPermissionsNames && store.getters.getAllPermissionsNames.length) {
    userPermissions = await store.getters.getAllPermissionsNames;
  } else if(store.getters.isAuthenticated) {
    await store.dispatch("actionPermissions");
    userPermissions = await store.getters.getAllPermissionsNames;
  }

  const requiredPermissions = await to.meta.permissionName || null;
  const hasPermission = await userPermissions.includes(requiredPermissions);

  if (store.getters.isAuthenticated && (hasPermission || requiredPermissions === null)) {
    const currentRoute = routes[requiredPermissions]
    console.log('go to 1:', currentRoute);
    return next();
  } else if (store.getters.isAuthenticated && !hasPermission) {
      const currentRoute = routes[userPermissions[0]]
      console.log('go to 2:', currentRoute);
      return next('/' + currentRoute);
  } else if (store.getters.isAuthenticated && !hasPermission) {
    console.log('back to 1:',window.location.search);
    return next(`/${window.location.search}`)
  }
  return next(`/${window.location.search}`)
}

const authentication = isAuthenticated

const routes = {
  Dashboard: 'dashboard',
  Sales: 'vendas',
  Contracts: 'contratos',
  Commissions: 'comissoes',
  Products: 'produtos',
  Notes: 'central-de-notas',
  Links: 'links',
  Checkouts:'checkouts',
  Reports:'relatorios', 
  Statements: 'extrato',
  Coupons: 'cupom',
  Leads: 'gestao-leads',
  Claims: 'reclamacoes',
  Integrations: "integracoes",
}

export default authentication