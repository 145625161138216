import middleware from '@/router/middleware'
import authentication from '@/router/middleware/authentication'
import unauthentication from '@/router/middleware/unauthentication'
import store from '@/store/index'

export default [{
    path: '/',
    name: 'Login',
    component: () => import('../../views/Seller/Login.vue'),
    beforeEnter: middleware([unauthentication]),
  },
  {
    path: '/verificar-email',
    name: 'VerificaEmail',
    component: () => import('../../views/Seller/Verification.vue'),
    meta: {
      sidebar: false
    }
  },
  {
    path: '/verificar-email/:hash',
    name: 'VerificaEmailHash',
    component: () => import('../../views/Seller/Verification.vue'),
    meta: {
      sidebar: false
    }
  },
  {
    path: '/login-greenn',
    name: 'LoginGreenn',
    component: () => import('../../views/Seller/Logingreen.vue'),
    meta: {
      sidebar: false
    }
  },
  {
    path: '/login-greenn-afiliado',
    name: 'afiliado',
    component: () => import('../../views/Seller/AffiliateInvitation.vue'),
    meta: {
      sidebar: false
    }
  },
  {
    path: '/registro',
    name: 'Seller-Register',
    component: () => import('../../views/Seller/Register.vue'),
    beforeEnter: middleware([unauthentication]),
  },
  {
    path: '/recrutamento/:hash',
    name: 'Recruitment',
    component: () => import('../../views/Seller/Recruitment.vue'),
  },
  {
    path: '/download/:product_id/:sale_id/:hash',
    name: 'DownloadFiles',
    component: () => import('../../views/Client/DownloadFiles.vue'),
  },
  {
    path: '/avaliacao/',
    name: 'Avaliacao',
    component: () => import('../../views/Client/Avaliacao.vue'),
  },
  {
    path: '/avaliacao/atendente',
    name: 'AttendantEvaluation',
    component: () => import('../../views/Client/AttendantEvaluation.vue'),
  },
  {
    path: '/recuperar-senha/',
    name: 'Seller-Password-Recovery',
    component: () => import('../../views/Seller/PasswordRecovery.vue'),
    beforeEnter: middleware([unauthentication]),
  },
  {
    path: '/recuperar-senha/:token',
    name: 'Seller-Password-Recovery-Token',
    component: () => import('../../views/Seller/PasswordRecovery.vue'),
    beforeEnter: middleware([unauthentication]),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../../views/Seller/Dashboard.vue'),
    beforeEnter: middleware([authentication]),
    meta: {
      permissionName: 'Dashboard'
    }
  },
  {
    path: '/vendas/',
    name: 'Sales',
    component: () => import('../../views/Seller/Sales.vue'),
    beforeEnter: middleware([authentication]),
    meta: {
      permissionName: 'Sales'
    }
  },
  {
    path: '/comissoes',
    name: 'Comissions',
    component: () => import('../../views/Seller/Comissions.vue'),
    beforeEnter: middleware([authentication]),
    meta: {
      permissionName: 'Commissions'
    }
  },
  {
    path: '/termos-comissoes',
    name: 'ComissionsTerms',
    component: () => import('../../views/Seller/ComissionsTerms.vue'),
    beforeEnter: middleware([authentication]),
    meta: {
      permissionName: 'Commissions'
    }
  },

  {
    path: '/contratos',
    name: 'Contracts',
    component: () => import('../../views/Seller/Contracts.vue'),
    beforeEnter: middleware([authentication]),
    meta: {
      permissionName: 'Contracts'
    }
  },
  {
    path: '/produtos',
    name: 'Products',
    component: () => import('../../views/Seller/Products.vue'),
    beforeEnter: middleware([authentication]),
    meta: {
      permissionName: 'Products'
    }
  },

  {
    path: '/produtos/edicao-produto/:id_product/',
    name: 'EditProduct',
    component: () => import('../../views/Seller/EditProduct.vue'),
    beforeEnter: middleware([authentication]),
    meta: {
      permissionName: 'Products'
    }
  },
  {
    path: '/produtos/edicao-produto/:id_product/:code',
    name: 'EditProduct-Code',
    component: () => import('../../views/Seller/EditProduct.vue'),
    beforeEnter: middleware([authentication]),
    meta: {
      permissionName: 'Products'
    }
  },
  {
    path: '/extrato',
    name: 'Statements',
    component: () => import('../../views/Seller/Statements.vue'),
    beforeEnter: middleware([authentication]),
    meta: {
      permissionName: 'Statements'
    }
  },
  {
    path: '/afiliados',
    name: 'Affiliation',
    component: () => import('../../views/Seller/Affiliation.vue'),
    beforeEnter: middleware([authentication]),
    meta: {
      permissionName: 'Affiliates'
    }
  },

  {
    path: '/links',
    name: 'Links',
    component: () => import('../../views/Seller/Links.vue'),
    beforeEnter: middleware([authentication]),
    meta: {
      permissionName: 'Links'
    }
  },
  {
    path: '/checkouts',
    name: 'Checkouts',
    component: () => import('../../views/Seller/Checkouts.vue'),
    beforeEnter: middleware([authentication]),
    meta: {
      permissionName: 'Checkouts'
    }
  },
   {
     path: '/cupom',
     name: 'Coupon',
     component: () => import('../../views/Seller/Cupom.vue'),
     beforeEnter: middleware([authentication]),
     meta: {
       permissionName: 'Coupons'
     }
   },
  {
    path: '/configuracoes',
    name: 'Settings',
    component: () => import('../../views/Seller/Settings.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/minha-conta',
    name: 'MyAccount',
    component: () => import('../../views/Seller/MyAccount.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/definir-senha/:token',
    name: 'SetupPassword',
    component: () => import('../../views/Seller/SetupPassword.vue'),
    beforeEnter: middleware([unauthentication]),
  },
  // Client
  {
    path: '/cliente/registro',
    name: 'Client-Register',
    component: () => import('../../views/Client/Register.vue'),
    beforeEnter: middleware([unauthentication]),
  },
  {
    path: '/compras',
    name: 'Client-Purchases',
    component: () => import('../../views/Client/Purchases.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/assinaturas',
    name: 'Client-Subscriptions',
    component: () => import('../../views/Client/Subscriptions.vue'),
    beforeEnter: middleware([authentication]),
  },
  // Common
  {
    name: 'AdminLogin',
    path: '/admin-login/:userid',
    component: () => import('../../views/AdminLogin.vue'),
  },
  {
    name: 'Redirect',
    path: '/redirect/:token',
    component: () => import('../../views/Redirect.vue'),
  },
  {
    name: 'Redirect-Google',
    path: '/g/:token',
    component: () => import('../../views/Redirect.vue'),
  },
  {
    name: 'AffiliationInvite',
    path: '/afiliacao-solicitacao/:token',
    component: () => import('../../views/AffiliationInvite.vue'),
    beforeEnter: middleware([unauthentication])
  },
  {
    path: '/vitrine',
    name: 'Vitrine',
    component: () => import('../../views/Seller/Store.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/central-de-notas',
    name: 'NoteCenter',
    component: () => import('../../views/Seller/NoteCenter.vue'),
    beforeEnter: middleware([authentication]),
    meta: {
      permissionName: 'Notes'
    }
  },
  {
    path: '/login-to-google/:auth/:title',
    name: 'Login Google',
    component: () =>
        import ('../../views/Seller/LoginGoogleOff.vue'),
  },
  {
    path: '/relatorios',
    name: 'reports',
    component: () => import('../../views/Seller/Reports/Main.vue'),
    beforeEnter: middleware([authentication]),
    meta: {
      permissionName: 'Reports'
    }
  },
  {
    path: '/relatorios/liberacao_saldo',
    name: 'reports-release-balance',
    component: () => import('../../views/Seller/Reports/Balances.vue'),
    beforeEnter: middleware([authentication]),
    meta: {
      permissionName: 'Reports'
    }
  },
  {
    path: '/relatorios/potencial_receita',
    name: 'reports-potential-revenue',
    component: () => import('../../views/Seller/Reports/PotentialRevenue.vue'),
    beforeEnter: middleware([authentication]),
    meta: {
      permissionName: 'Reports'
    }
  },
  {
    path: '/relatorios/recusas_cc',
    name: 'reports-refused-card',
    component: () => import('../../views/Seller/Reports/CreditCardRefuses.vue'),
    beforeEnter: middleware([authentication]),
    meta: {
      permissionName: 'Reports'
    }
  },
  {
    path: '/integracoes',
    name: 'Integrations',
    component: () => import('../../views/Seller/Integrations/Integrations.vue'),
    beforeEnter: middleware([authentication]),
    meta: {
      permissionName: 'Integrations'
    }
  },
  {
    path: '/integracoes/logs-de-envio',
    name: 'IntegrationsLog',
    component: () => import('../../views/Seller/Integrations/IntegrationsLog.vue'),
    beforeEnter: middleware([authentication]),
    meta: {
      permissionName: 'Integrations'
    }
  },
  {
    path: '/integracoes/:integration',
    name: 'IntegrationConfig',
    component: () => import('../../views/Seller/Integrations/IntegrationsConfig.vue'),
    beforeEnter: middleware([authentication]),
    meta: {
      permissionName: 'Integrations'
    }
  },
  {
    path: '/gestao-leads',
    name: 'Leads',
    component: () => import('../../views/Seller/Leads.vue'),
    beforeEnter: middleware([authentication]),
    meta: {
      permissionName: 'Leads'
    }
  },
  {
    path: '/reclamacoes',
    name: 'Reclamações',
    component: () => import('../../views/Seller/Claim.vue'),
    beforeEnter: middleware([authentication]),
    meta: {
      permissionName: 'Claims'
    }
  },
  {
    name: 'pageNotFound',
    path: '*',
    component: () => import('../../views/PageNotFound.vue'),
    beforeEnter: () => {
      if (!store.getters.isAuthenticated) {
        console.log('pageNotFound','logoutRequest')
        store.dispatch("logoutRequest").catch((err) => {});
      }
    }
  }
]