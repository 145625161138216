var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-links"},[(!_vm.getMenuMobilePermissions.length)?_c('div',_vm._l((5),function(n,index){return _c('div',{key:index,staticClass:"item load pb-5 mb-5"},[_vm._m(0,true)])}),0):[_vm._l((_vm.getMenuMobilePermissions),function(menu,i){return [(
        (((!_vm.viewMoreMenu && i < _vm.indexViewMore) || (_vm.viewMoreMenu && i < _vm.indexViewMore)) &&
          menu.name === 'Play' &&
          !_vm.$store.getters.setPanel) ||
        (((!_vm.viewMoreMenu && i < _vm.indexViewMore) || (_vm.viewMoreMenu && i < _vm.indexViewMore)) &&
          menu.name === 'Play' &&
          _vm.$store.getters.setPanel)
      )?_c('a',{key:i,class:'menu-item-link menu-key-' + i,on:{"click":_vm.eventLoginClub}},[_c('img',{attrs:{"src":_vm.getImgUrlMenus(menu.name.toLowerCase()),"title":_vm.$t('side.' + menu.title),"alt":_vm.$t('side.' + menu.title)}}),_vm._v(" "+_vm._s(_vm.$t("side." + menu.name))+" ")]):_vm._e(),(
        (((!_vm.viewMoreMenu && i < _vm.indexViewMore) || (_vm.viewMoreMenu && i < _vm.indexViewMore)) &&
          menu.name !== 'Sales' &&
          menu.name !== 'Contracts' && 
          menu.name !== 'Commissions' &&
          menu.name !== 'Play' &&
          !_vm.$store.getters.setPanel) ||
        (((!_vm.viewMoreMenu && i < _vm.indexViewMore) || (_vm.viewMoreMenu && i < _vm.indexViewMore)) &&
          menu.name !== 'Sales' &&
          menu.name !== 'Contracts' && 
          menu.name !== 'Commissions' &&
          menu.name !== 'Play' &&
          _vm.$store.getters.setPanel)
      )?_c('router-link',{key:i,class:'menu-item-link menu-key-' + i,attrs:{"to":`/${_vm.$t('route.' + menu.name.toLowerCase())}`}},[_c('img',{attrs:{"src":_vm.getImgUrlMenus(menu.name.toLowerCase()),"title":_vm.$t('side.' + menu.title),"alt":_vm.$t('side.' + menu.title)}}),_vm._v(" "+_vm._s(_vm.$t("side." + menu.title))+" "),(menu.name.toLowerCase() === 'integrations')?_c('span',{staticClass:"menu-new"},[_vm._v("Novo")]):_vm._e()]):_vm._e(),(
          (((!_vm.viewMoreMenu && i < _vm.indexViewMore) || (_vm.viewMoreMenu && i < _vm.indexViewMore)) &&
            menu.name === _vm.subMenuEnabled &&
            !_vm.$store.getters.setPanel) ||
          (((!_vm.viewMoreMenu && i < _vm.indexViewMore) || (_vm.viewMoreMenu && i < _vm.indexViewMore)) &&
            menu.name === _vm.subMenuEnabled &&
            _vm.$store.getters.setPanel)
        )?_c('a',{key:i,class:'menu-item-link menu-key-' + i,on:{"click":_vm.subMenu01}},[_c('img',{attrs:{"src":_vm.getImgUrlMenus(menu.name.toLowerCase())}}),_vm._v(" "+_vm._s(_vm.$t("side." + menu.name))+" "),_c('img',{staticClass:"arrow",class:{ rotateArrow: _vm.sub_menu },attrs:{"src":require("@/assets/img/icons/arrow-fill.svg")}})]):_vm._e()]}),((_vm.viewMoreMenu && _vm.$store.getters.setPanel) || !_vm.$store.getters.setPanel)?_c('a',{on:{"click":_vm.subMenu02}},[_c('img',{attrs:{"src":require("@/assets/img/icons/more.svg")}}),_vm._v(_vm._s(_vm.$t("side.text_1970"))+" "),_c('img',{staticClass:"arrow",class:{ rotateArrow: _vm.sub_menu2 },attrs:{"src":require("@/assets/img/icons/arrow-fill.svg")}})]):_vm._e(),_c('Logout'),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.sub_menu),expression:"sub_menu"}],staticClass:"sub-menu",attrs:{"id":"sub_01"}},[_c('li',{on:{"click":function($event){_vm.sub_menu = false}}},[_vm._l((_vm.getSubmenuSalesPermissions),function(menu,i){return [(menu.name === 'Sales')?_c('router-link',{key:i,class:'menu-key-' + i,attrs:{"to":`/${_vm.$t('route.' + menu.name.toLowerCase())}`}},[_c('img',{attrs:{"src":_vm.getImgUrlMenus(menu.name.toLowerCase()),"title":_vm.$t('side.' + menu.title),"alt":_vm.$t('side.' + menu.title)}}),_vm._v(" "+_vm._s(_vm.$t("side." + menu.title))+" ")]):_vm._e(),(menu.name === 'Contracts')?_c('router-link',{key:i,class:'menu-key-' + i,attrs:{"to":`/${_vm.$t('route.' + menu.name.toLowerCase())}`}},[_c('img',{attrs:{"src":_vm.getImgUrlMenus(menu.name.toLowerCase()),"title":_vm.$t('side.' + menu.title),"alt":_vm.$t('side.' + menu.title)}}),_vm._v(" "+_vm._s(_vm.$t("side." + menu.title))+" ")]):_vm._e(),(
            menu.name === 'Commissions' && _vm.ambassadorEnabled && _vm.ambassadorAcceptedTerms
          )?_c('router-link',{key:i,class:'menu-key-' + i,attrs:{"to":`/${_vm.$t('route.' + menu.name.toLowerCase())}`}},[_c('img',{attrs:{"src":_vm.getImgUrlMenus(menu.name.toLowerCase()),"title":_vm.$t('side.' + menu.title),"alt":_vm.$t('side.' + menu.title)}}),_vm._v(" "+_vm._s(_vm.$t("side." + menu.title))+" ")]):_vm._e(),(
            menu.name === 'Commissions' && _vm.ambassadorEnabled && !_vm.ambassadorAcceptedTerms
          )?_c('router-link',{key:i,class:'menu-key-' + i,attrs:{"to":`/termos-comissoes`}},[_c('img',{attrs:{"src":_vm.getImgUrlMenus(menu.name.toLowerCase()),"title":_vm.$t('side.' + menu.title),"alt":_vm.$t('side.' + menu.title)}}),_vm._v(" "+_vm._s(_vm.$t("side." + menu.title))+" ")]):_vm._e()]})],2)]),(_vm.viewMoreMenu)?_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.sub_menu2),expression:"sub_menu2"}],staticClass:"sub-menu sub-menu2",attrs:{"id":"sub_02"}},[_c('li',{on:{"click":function($event){_vm.sub_menu2 = false}}},[_c('b-tooltip',{attrs:{"target":"club-menu","title":_vm.$t('side.text_1971'),"placement":"left"}}),_vm._l((_vm.getMenuMobilePermissions),function(menu,i){return [(
            _vm.viewMoreMenu &&
            i > (_vm.indexViewMore-1) &&
            menu.name === 'Play' &&
            (!_vm.$store.getters.setPanel || _vm.$store.getters.setPanel)
          )?_c('a',{key:i,class:'menu-key-' + i,attrs:{"id":menu.name.toLowerCase()},on:{"click":_vm.eventLoginClub}},[_c('img',{attrs:{"src":_vm.getImgUrlMenus(menu.name.toLowerCase())}}),_vm._v(" "+_vm._s(_vm.$t("side." + menu.title))+" ")]):_vm._e(),(
            _vm.viewMoreMenu &&
            i > (_vm.indexViewMore-1) &&
            menu.name !== 'Play' &&
            (!_vm.$store.getters.setPanel || _vm.$store.getters.setPanel)
          )?_c('router-link',{key:i,class:'menu-key-' + i,attrs:{"id":menu.name.toLowerCase(),"to":`/${_vm.$t('route.' + menu.name.toLowerCase())}`}},[_c('img',{attrs:{"src":_vm.getImgUrlMenus(menu.name.toLowerCase())}}),_vm._v(" "+_vm._s(_vm.$t("side." + menu.title))+" "),(menu.name.toLowerCase() === 'integrations')?_c('span',{staticClass:"menu-new"},[_vm._v("Novo")]):_vm._e()]):_vm._e()]})],2)]):_vm._e()]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-item-loading"},[_c('div',{staticClass:"icone"}),_c('div',{staticClass:"dado"},[_c('div',{staticClass:"up"},[_c('span',[_vm._v("--")])])])])
}]

export { render, staticRenderFns }